import React from "react"
import { Link as GatsbyLink } from 'gatsby';
import { Link } from "@reach/router"
import PropTypes from "prop-types"
import abcImgName from "../../images/abc.svg"
import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react"

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH="70vh"
      px={8}
      mb={16}
      {...rest}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="#1b1511"
          textAlign={["center", "center", "left", "left"]}
        >
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="#1b1511"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>
        <Stack direction="row">
          <a href="/logistic-regression-guide">
            <Button
              colorScheme="teal"
              borderRadius="8px"
              py="4"
              px="4"
              lineHeight="1"
              size="md"
            >
            Learn More
            </Button>
          </a>
          <a href="/get-started">
            <Button
              colorScheme="red"
              borderRadius="8px"
              py="4"
              px="4"
              lineHeight="1"
              size="md"
            >
            Get Started
            </Button>
          </a>
        </Stack>
      </Stack>
      <Box w={{ base: "40%", sm: "30%", md: "25%" }} mb={{ base: 12, md: 0 }}>
        <Image src={abcImgName} size="100%"/>
      </Box>
    </Flex>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

Hero.defaultProps = {
  title: "React landing page with Chakra UI",
  subtitle:
    "This is the subheader section where you describe the basic benefits of your product",
  image: "https://source.unsplash.com/collection/404339/800x600",
  ctaText: "Create your account now",
  ctaLink: "/signup",
}
