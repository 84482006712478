import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Landing() {
  return (
    <LandingLayout>
      <Helmet title="Efficiently Tune Model Parameters | buildingblock.ai"/>
      <Hero
        title="Precisely and Efficiently Tune Models to Make Better Predictions"
        subtitle=""
      />
    </LandingLayout>
  );
}
